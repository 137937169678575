@import './../../../css/var';

.audio_player_track_list_item {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-family: $main-font-family;
    font-size: 14px;
    line-height: 1.4;
    margin: 0;
    min-height: 48px;
    padding: 0 6px 0 0;

    &:hover,
    &.audio_player_track_list_item__active {
        background-color: #e8e8e8;
    }
}

.button {
    align-items: center;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    //float: left;
    height: 48px;
    justify-content: center;
    margin: 0;
    outline: none;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 48px;
}

%button--image,
.button_image {
    background-color: #cbcbcb;
    border: 3px solid #cbcbcb;
    border-radius: 100%;
    display: block;
    flex-shrink: 0;
    height: 18px;
    position: relative;
    width: 18px;
    z-index: 10;
}

.button_image__active {
    @extend %button--image;

    background-color: #d2dff5;
    border: 3px solid #d2dff5;
}

.content {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    //margin-left: 48px;
    min-height: 48px;
    overflow: hidden;
    padding: 6px 8px 6px 0;
}

.track_title {
    display: block;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    margin: 0;
    overflow: hidden;
    padding: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.audio_tag {
    display: none;
}

.track_time {
    flex-shrink: 0;
    padding-right: 5px;
}

.play_list_menu_button {
    svg {
        background-color: #fff;
    }
}
