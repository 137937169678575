.audio_player_track_list {
    background-color: #f5f5f5;
    border-bottom: 4px solid #fff;
    border-top: 4px solid #fff;
    box-sizing: border-box;
    display: block;
    margin: 0;
    padding: 0;
    width: 100%;
}
