.audio_player_head {
    background-color: #fff;
    box-sizing: border-box;
    display: block;
    //height: 96px;
    padding: 13px 11px 7px;
    position: relative;
    width: 100%;

    .spinner {
        left: 15px;
        top: 19px;
    }
}
