.audio_player_head_playing_bar {
    align-items: center;
    display: flex;
    flex-direction: row;
}

.time {
    display: block;
    padding: 0 4px;
}

.volume_bar {
    flex-shrink: 0;
    width: 76px;
}
