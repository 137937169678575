@import './../../css/var';

.time {
    color: $main-text-color;
    display: block;
    flex-shrink: 0;
    font-family: $main-font-family;
    font-size: 12px;
    line-height: 1;
    margin: 0;
    padding: 0;
    //pointer-events: none;
}
