@import './../../css/var';

$border-radius: 4px;
$button-image-size: 28px;
$button-size: 38px;
$button-border-size: 1px;
$button-border-color: #5a5a5a;
$button-image-active-background-color: #e5e5e5;

.audio_player_control__button__image {
    border-radius: $border-radius;
    box-sizing: border-box;
    display: block;
    height: $button-image-size;
    padding: 4px;
    width: $button-image-size;
}

.audio_player_control__button {
    background-color: transparent;
    border: $button-border-size solid transparent;
    border-radius: $border-radius;
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    flex-shrink: 0;
    height: $button-size;
    margin: 0;
    outline: none;
    //padding: ($button-size - $button-image-size) / 2 - $button-border-size; === 4px
    padding: 4px;
    width: $button-size;

    &:active,
    &.audio_player_control__button__active {
        .audio_player_control__button__image {
            background-color: $button-image-active-background-color;
        }
    }

    &.audio_player_control__button__disabled {
        opacity: 0.75;
        pointer-events: none;
    }

    &.audio_player_control__button__bordered {
        border: 1px solid $button-border-color;
    }
}

@media screen and (max-width: $narrow-screen-width) {
    .audio_player_control__hide_for_narrow {
        display: none;
    }
}
