.Treebg {
  background-image: url("../src/assets/Treebg.png");
  background-size: cover;
  /* background-position: center center; */
  background-repeat: no-repeat;
  min-height: 100vh;
  width: 100%;
}
.Treeresultbg {
  background-image: url("../src/assets/Treebg.png");
  background-size: cover;
  /* background-position: center center; */
  background-repeat: no-repeat;
  min-height: 100vh;
  width: 100%;
}
.RoundBorder {
  background-image: url("../src/assets/round-border.png");
  background-repeat: no-repeat;
  position: relative;
}
.Treeblogsbg {
  background-image: url("../src/assets/Treebg.png");
  background-size: cover;
  /* background-position: center center; */
  background-repeat: no-repeat;
  min-height: 100vh;
  width: 100%;
}
.Treequestionbg {
  background-image: url("../src/assets/Treebg.png");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  width: 100%;
}
.Treefooterbg {
  background-image: url("../src/assets/Treebg.png");
  background-size: cover;
  /* background-position: center center; */
  background-repeat: no-repeat;
  min-height: 100%;
  width: 100%;
}
@media screen and (min-device-width: 320px) and (max-device-width: 768px) {
  .Treebg {
    background-image: url("../src/assets/Treebg.png");
    /* background-size:unset; */
    /* background-position: center center; */
    background-repeat: no-repeat;
    min-height: 100vh;
    width: 100%;
  }
  .Treeblogsbg {
    background-image: url("../src/assets/Treebg.png");
    /* background-size:unset; */
    background-repeat: no-repeat;
    min-height: 100vh;
    width: 100%;
  }
  .Treequestionbg {
    background-image: url("../src/assets/Treebg.png");
    /* background-size:unset; */
    background-repeat: no-repeat;
    min-height: 100vh;
    width: 100%;
  }

  .mobile-footer {
    margin-top: -0.5rem;
  }
  .categories-footer {
    margin-top: -0.5rem;
  }
}
.signupbg {
  background-image: url("../src/assets/signupbg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.categoriesbg {
  background-image: url("../src/assets/categoriesbg.png");
  background-size: 100% 100%;
  background-position: top center;
  background-repeat: no-repeat;
}
.categorybg {
  background-image: url("../src/assets/categorybg.png");
  background-size: 100% 100%;
  background-position: top center;
  background-repeat: no-repeat;
}
.welcomebg {
  background-image: url("../src/assets/welcomebg.png");
  background-size: 100% 100%;
  background-position: top center;
  background-repeat: no-repeat;
}
.surveybg {
  /* background-image: url('../src/assets/surveybg.png'); */
  /* background-image: url('../src/assets/surveybgnew.png'); */
  /* background-image: url('../src/assets/resultbg.png'); */
  background-image: url("../src/assets/resultbg.png");
  /* background-size: 100% 100%;
  background-position: top center; */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.dr-meditation-code {
  background-color: #ecf6e7;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.Music {
  background-image: url("../src/assets/NewMusic.png");
  background-size: 100% 100%;
  background-position: top center;
  background-repeat: no-repeat;
}

.Blogs {
  background-image: url("../src/assets/NewBlogs.png");
  background-size: 100% 100%;
  background-position: top center;
  background-repeat: no-repeat;
}
.Video {
  background-image: url("../src/assets/NewVideo.png");
  background-size: 100% 100%;
  background-position: top center;
  background-repeat: no-repeat;
}
.Podcasts {
  background-image: url("../src/assets/NewPodcast.png");
  background-size: 100% 100%;
  background-position: top center;
  background-repeat: no-repeat;
}
.resultcatrgoriesbg {
  background-image: url("../src/assets/resultbg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.footerbg {
  background-image: url("../src/assets/resultbg.png");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}
.MainBlogsbg {
  background-image: url("../src/assets/BlogBg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.MainPodcastsbg {
  background-image: url("../src/assets/PodcastBg.jpeg");
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
}
.Musicsbg {
  background-image: url("../src/assets/MusicBg.png");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}
.Videobg {
  background-image: url("../src/assets/VideosBg.png");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}
/* Home banner */
.Homebanner {
  background-image: url("../src/assets/homebanner.jpg");
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
}
.Advisor {
  background-image: url("../src/assets/Advisor.png");
  background-size: 100% 100%;
  background-position: top center;
  background-repeat: no-repeat;
}
.ganeralbanner {
  background-image: url("../src/assets/ganeralbanner.jpg");
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
}
.meditation {
  background-image: url("../src/assets/meditation.png");
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
}
