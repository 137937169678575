@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter+Tight&display=swap");

/* @layer base {
  h1 {
    @apply text-black;
  }
  h2 {
    @apply text-black;
  }
  h3 {
    @apply text-black;
  }
  h4 {
    @apply text-black;
  }
  h5 {
    @apply text-black;
  }
  h6 {
    @apply text-black;
  }
  p {
    @apply text-black;
  }
  a {
    @apply text-black;
  }
  input {
    @apply text-black;
  }
  li {
    @apply text-black;
  }
  button {
    @apply text-black;
  }
} */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: revert;
  font-weight: revert;
}
.icon-span {
  cursor: pointer;
}

.tracker-box {
  background-color: #ecf5df;
  font-size: 14px;
  color: red;
  letter-spacing: 0.09em;
  padding: 15px;
  border-radius: 5px;
  margin-top: 1rem;
}

.tracker-box div {
  margin: 5px 0;
}

.tracker-box .validated {
  color: green;
}

.list-icon {
  margin-right: 8px;
}

.list-icon.green {
  color: green;
}
.react-tel-input .form-control {
  @apply !w-[18rem]  !rounded-3xl !text-base;
}
.react-tel-input .flag-dropdown {
  @apply !rounded-l-full !bg-white;
}
.react-tel-input .selected-flag {
  @apply hover:!rounded-l-full;
}

.opt-container {
  @apply flex justify-between gap-2;
}
.opt-container input {
  @apply !mr-0 flex-1 !w-[35px] py-5 outline-none;
}
div > .w-fit {
  @apply hover:!text-[#90be23];
}

div[aria-label="Tabs with underline"] button {
  color: green;
  border-bottom-color: green;
}
div[role="dialog"] {
  @apply shadow-none;
}
div[role="dialog"] div {
  @apply border-none;
}

.fa9e1a {
  background-color: #91bd1f !important;
  border-radius: 10px !important;
}

.e0b58b {
  background-color: transparent !important;
}
.f79532 {
  background-color: white !important;
}
.c44fc5 {
  background-color: green !important;
}
